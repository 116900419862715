'use strict'

// Website wide scripts
// @Author Dummy Team

console.log('Allo! Selamat datang di website Gamaliel Kids Development Center.');

var toggle = document.querySelector('.menu-toggle');
var overlay = document.querySelector('.overlay');
var asideMenu = document.querySelector('.content-page>aside');
var btnShare = document.querySelector('.btn-share');
var btnHide = document.querySelector('.share-box>.hide');
var shareBox = document.querySelector('.share-content');
if(toggle) {
  toggle.addEventListener('click', function(e) {
    e.preventDefault();
    overlay.classList.add('active');
    asideMenu.classList.add('active');
  });
}
if(overlay) {
  overlay.addEventListener('click', function(e) {
    e.preventDefault();
    overlay.classList.remove('active');
    asideMenu.classList.remove('active');
  });
}
if(btnShare) {
  btnShare.addEventListener('click', function(e) {
    e.preventDefault();
    shareBox.classList.add('active');
    btnShare.style.display = 'none';
  });
}
if(btnHide) {
  btnHide.addEventListener('click', function(e) {
    e.preventDefault();
    shareBox.classList.remove('active');
    btnShare.style.display = 'block';
  });
}